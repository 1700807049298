import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";

// Utils
import { axiosInstance } from "../../baseAxios";
import { Contacts } from "../../data";

// Components
import Button from "../../components/button";
import AuthContext from "../../context/auth";

const WaitList = () => {
  const [data, setData] = useState(Contacts);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(new Set());
  //   useEffect(() => {
  //     const fetchData = async () => {
  //       await axiosInstance
  //         .get("/waitlist")
  //         .then((res) => {
  //           console.log(res.data.data);
  //           setData(res.data.data);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           toast.error(err.message);
  //         });
  //     };
  //     fetchData();
  //   }, []);

  const sortByDate = () => {
    const tempData = [...data];
    tempData.sort((a, b) => {
      return new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1;
    });
    setData(tempData);
  };
  const sortByName = () => {
    const tempData = [...data];
    tempData.sort((a, b) => {
      return b.firstName.toUpperCase > a.firstName.toUpperCase ? 1 : -1;
    });
    setData(tempData);
  };
  const sortByType = () => {
    const tempData = [...data];
    tempData.sort((a, b) => {
      return b.mailType > a.mailType ? 1 : -1;
    });
    setData(tempData);
  };

  //   const insertToDb = useCallback(async () => {
  //     await axiosInstance
  //       .post("bulk/waitlist", { data: datab })
  //       .then((res) => {
  //         toast.success(res.data.message);
  //       })
  //       .catch((err) => toast.error(err.message));
  //   }, []);
  const sendMail = useCallback(async () => {
    const toSendList = data.filter((item) => selected.has(item.email));
    setLoading(true);
    await axiosInstance
      .post("/send/waitlist", {
        data: toSendList,
      })
      .then((res) => {
        toast.success(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  }, [data, selected]);
  const handleCheck = (email) => {
    const picked = new Set(selected);
    if (picked.has(email)) {
      picked.delete(email);
    } else {
      picked.add(email);
    }
    setSelected(picked);
  };
  const checkAll = () => {
    if (selected.size !== data.length) {
      const temp = data.map((item) => item.email);
      setSelected(new Set(temp));
    } else {
      setSelected(new Set());
    }
  };
  return (
    <AuthContext.Consumer>
      {({ isAuthenticated }) => {
        if(!isAuthenticated) {return null}
        return (
          <div className="w-full h-full container mx-auto">
            {showModal ? (
              <div className="fixed inset-0 flex items-center justify-center h-full w-full bg-white/50">
                <div className="bg-white rounded border p-20">
                  <p className="-mt-16 mb-8 text-center">Confirm sending!</p>
                  <div className="flex items-center justify-center gap-x-10 ">
                    <Button
                      title={loading ? "Wait..." : "Continue"}
                      onClick={() => sendMail()}
                    />
                    <Button
                      title="Cancel"
                      onClick={() => setShowModal(false)}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="flex my-10 items-center justify-between">
              <div className="">Total: {data.length}</div>
              <div className="">Selected: {selected.size}</div>
              <Button
                title="Send Mail"
                // onClick={() => setShowModal(true)}
                className=""
              />
              {/* <Button
          title="Insert to db"
          onClick={() => insertToDb()}
          className=""
        /> */}
            </div>
            <table className="table-auto w-full max-w-3xl mx-auto">
              <thead>
                <tr>
                  <th className="w-10 text-left">
                    <input type="checkbox" onChange={checkAll} />
                  </th>
                  <th className="text-left cursor-pointer" onClick={sortByName}>
                    First Name
                  </th>
                  <th className="text-left">Email</th>
                  <th
                    className="text-left cursor-pointer px-10"
                    onClick={sortByType}
                  >
                    Type
                  </th>
                  <th className="text-left cursor-pointer" onClick={sortByDate}>
                    Date
                  </th>
                  <th className="text-left w-20"></th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item) => (
                  <tr key={item.email} className="group">
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) => handleCheck(e.target.value)}
                        value={item.email}
                        checked={selected.has(item.email)}
                      />
                    </td>
                    <td>{item.firstName}</td>
                    <td>{item.email}</td>
                    <td className="px-10">{item.mailType}</td>
                    <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                    {/* <td>
                <span
                  className="hidden cursor-pointer group-hover:inline-block 
                                py-0.5 px-1 rounded text-red-alert border text-tiny ml-5"
                  onClick={() => deleteContact(item.email)}
                >
                  Delete
                </span>
              </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      }}
    </AuthContext.Consumer>
  );
};

export default WaitList;
