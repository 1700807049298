export const Contacts = [
    {
        "mailType": "WAITLIST",
        "_id": "641abc8e78b55f4de5a2416f",
        "email": "wilsthebrand@gmail.com",
        "createdAt": "2023-03-22T08:30:06.712Z",
        "firstName": "Wilson"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641abd3978b55f4de5a37768",
        "email": "aoshobugie@gmail.com",
        "createdAt": "2023-03-22T08:32:57.811Z",
        "firstName": "Abdulhameed"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641abe2278b55f4de5a50e24",
        "email": "akinyanjuadedamola@gmail.com",
        "createdAt": "2023-03-22T08:36:50.443Z",
        "firstName": "Rhema"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641ac54b78b55f4de5b1a9ff",
        "email": "omoyenioyedokun@gmail.com",
        "createdAt": "2023-03-22T09:07:23.693Z",
        "firstName": "Yeni"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641ae59178b55f4de5ecd73b",
        "email": "emmaolowoo@gmail.com",
        "createdAt": "2023-03-22T11:25:05.475Z",
        "firstName": "Emmanuel"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641ae88378b55f4de5f219de",
        "email": "nwodotriumph@gmail.com",
        "createdAt": "2023-03-22T11:37:39.171Z",
        "firstName": "Triumph"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641aef2578b55f4de5fd9384",
        "email": "consultthebrand@gmail.com",
        "createdAt": "2023-03-22T12:05:57.405Z",
        "firstName": "Tunde"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641aef9278b55f4de5fe4f11",
        "email": "adetayosobowale@gmail.com",
        "createdAt": "2023-03-22T12:07:46.131Z",
        "firstName": "Adetayo"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641affb978b55f4de51c122d",
        "email": "ikpemerex@gmail.com",
        "createdAt": "2023-03-22T13:16:41.650Z",
        "firstName": "Idara"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641b013678b55f4de51e9621",
        "email": "janesuccess2@gmail.com",
        "createdAt": "2023-03-22T13:23:02.149Z",
        "firstName": "Jane"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641b067178b55f4de5271611",
        "email": "johnsonigwe83@gmail.com",
        "createdAt": "2023-03-22T13:45:21.869Z",
        "firstName": "Johnson"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641b07d078b55f4de5297a66",
        "email": "Lexanda12@gmail.com",
        "createdAt": "2023-03-22T13:51:12.404Z",
        "firstName": "Eniola"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641b0b8378b55f4de5301d00",
        "email": "samsonemmanuel24@gmail.com",
        "createdAt": "2023-03-22T14:06:59.042Z",
        "firstName": "Emmanuel"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641b1bc878b55f4de54e18a5",
        "email": "missflorence97@gmail.com",
        "createdAt": "2023-03-22T15:16:24.830Z",
        "firstName": "Berry"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641b579978b55f4de5bb8eeb",
        "email": "oluwagbamifavour@gmail.com",
        "createdAt": "2023-03-22T19:31:37.142Z",
        "firstName": "Favour"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c3e5278b55f4de559bd02",
        "email": "sibandabongz@gmail.com",
        "createdAt": "2023-03-23T11:56:02.759Z",
        "firstName": "Bongani"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c534878b55f4de5814aed",
        "email": "aisosaagboghidi24@gmail.com",
        "createdAt": "2023-03-23T13:25:28.315Z",
        "firstName": "Aisosa"
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c688e78b55f4de5a932e3",
        "email": "alexblum1989@gmail.com",
        "createdAt": "2023-03-23T14:56:14.286Z",
        "firstName": ""
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688e78b55f4de5a932e2",
        "email": "Presheoxzoe16@gmail.com",
        "createdAt": "2023-03-23T14:56:14.288Z",
        "firstName": "Shawn"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688e78b55f4de5a932ea",
        "email": "aisosaedosa.e@gmail.com",
        "createdAt": "2023-03-23T14:56:14.288Z",
        "firstName": "Aisosa Edosa"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688e78b55f4de5a932ed",
        "email": "eweremercy99@gmail.com",
        "createdAt": "2023-03-23T14:56:14.288Z",
        "firstName": "Mercy"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688e78b55f4de5a932f0",
        "email": "ikahunuagbon0110@gmail.com",
        "createdAt": "2023-03-23T14:56:14.288Z",
        "firstName": "Faith"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688e78b55f4de5a932eb",
        "email": "besttreasure1@gmail.com",
        "createdAt": "2023-03-23T14:56:14.288Z",
        "firstName": "Treasure"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688e78b55f4de5a932f3",
        "email": "temialex@gmail.com",
        "createdAt": "2023-03-23T14:56:14.289Z",
        "firstName": "Alex"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688e78b55f4de5a932f6",
        "email": "Doyinfolabi@gmail.com",
        "createdAt": "2023-03-23T14:56:14.288Z",
        "firstName": "Adedoyin"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688e78b55f4de5a932f9",
        "email": "freidamay2020@gmail.com",
        "createdAt": "2023-03-23T14:56:14.288Z",
        "firstName": "Freida"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688f78b55f4de5a93340",
        "email": "agataomar@gmail.com",
        "createdAt": "2023-03-23T14:56:14.289Z",
        "firstName": "Agata"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688f78b55f4de5a9334e",
        "email": "luminogoc@gmail.com",
        "createdAt": "2023-03-23T14:56:14.289Z",
        "firstName": "Ouwatobi"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688f78b55f4de5a93351",
        "email": "chidiebubemadubuike@gmail.com",
        "createdAt": "2023-03-23T14:56:14.289Z",
        "firstName": "June"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688f78b55f4de5a93354",
        "email": "pasealchidalu98@gmail.com",
        "createdAt": "2023-03-23T14:56:14.289Z",
        "firstName": "Paseal"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688f78b55f4de5a93350",
        "email": "benyadex11@gmail.com",
        "createdAt": "2023-03-23T14:56:14.289Z",
        "firstName": "Benjamin"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688f78b55f4de5a9335b",
        "email": "Ericawealth1@gmail.com",
        "createdAt": "2023-03-23T14:56:14.289Z",
        "firstName": "Erica"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688f78b55f4de5a93360",
        "email": "successisioma1@gmail.com",
        "createdAt": "2023-03-23T14:56:14.289Z",
        "firstName": "Isioma"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688f78b55f4de5a93358",
        "email": "iyreigns@gmail.com",
        "createdAt": "2023-03-23T14:56:14.289Z",
        "firstName": "Iyobosa"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688f78b55f4de5a9335d",
        "email": "davidotu200010@gmail.com",
        "createdAt": "2023-03-23T14:56:14.289Z",
        "firstName": "David"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688f78b55f4de5a933a5",
        "email": "ayosolaasake@gmail.com",
        "createdAt": "2023-03-23T14:56:14.289Z",
        "firstName": "Yetunde"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688f78b55f4de5a933be",
        "email": "ojimaochuma@gmail.com",
        "createdAt": "2023-03-23T14:56:14.290Z",
        "firstName": "Ojima"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688f78b55f4de5a933b7",
        "email": "nozyworld1@gmail.com",
        "createdAt": "2023-03-23T14:56:14.289Z",
        "firstName": "Nosakhare"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688f78b55f4de5a933c0",
        "email": "oluwamatthew@gmail.com",
        "createdAt": "2023-03-23T14:56:14.289Z",
        "firstName": "Mathew"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688f78b55f4de5a933ba",
        "email": "me@ogbemiro.com",
        "createdAt": "2023-03-23T14:56:14.290Z",
        "firstName": "Opeyemi"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c688f78b55f4de5a933d3",
        "email": "etsieyrose@gmail.com",
        "createdAt": "2023-03-23T14:56:14.290Z",
        "firstName": "Ari"
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bd978b55f4de5af95d3",
        "email": "annuals_fold.09@icloud.com",
        "createdAt": "2023-03-23T15:10:16.830Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bd978b55f4de5af95cc",
        "email": "jhmcclellandii@gmail.com",
        "createdAt": "2023-03-23T15:10:16.828Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bd978b55f4de5af95d2",
        "email": "iynabiodun@gmail.com",
        "createdAt": "2023-03-23T15:10:16.830Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bd978b55f4de5af9610",
        "email": "canhagoncalo@gmail.com",
        "createdAt": "2023-03-23T15:10:16.830Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bd978b55f4de5af9628",
        "email": "sennelokagiso@gmail.com",
        "createdAt": "2023-03-23T15:10:16.830Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bd978b55f4de5af9633",
        "email": "kabelomatsio@gmail.com",
        "createdAt": "2023-03-23T15:10:16.830Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bd978b55f4de5af9638",
        "email": "roel.kerstens@skynet.be",
        "createdAt": "2023-03-23T15:10:16.830Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bd978b55f4de5af963b",
        "email": "monicamatsio@gmail.com",
        "createdAt": "2023-03-23T15:10:16.830Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bd978b55f4de5af9655",
        "email": "ayomideosho13@gmail.com",
        "createdAt": "2023-03-23T15:10:16.831Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bd978b55f4de5af965e",
        "email": "onkgopotse@thepocketcouch.com",
        "createdAt": "2023-03-23T15:10:16.830Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bd978b55f4de5af9649",
        "email": "kayode@caena.io",
        "createdAt": "2023-03-23T15:10:16.830Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bd978b55f4de5af9658",
        "email": "tioluwanimiadeniji@gmail.com",
        "createdAt": "2023-03-23T15:10:16.830Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bd978b55f4de5af9668",
        "email": "Moshabi.Pitsoane.p@gmail.com",
        "createdAt": "2023-03-23T15:10:16.831Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bda78b55f4de5af96b7",
        "email": "bolajiefawole@gmail.com",
        "createdAt": "2023-03-23T15:10:16.831Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bda78b55f4de5af9708",
        "email": "oluwafikayof@gmail.com",
        "createdAt": "2023-03-23T15:10:16.832Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bda78b55f4de5af971f",
        "email": "ibukunoluwahotonu@gmail.com",
        "createdAt": "2023-03-23T15:10:16.832Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bda78b55f4de5af972e",
        "email": "adetiloyecontinental@gmail.com",
        "createdAt": "2023-03-23T15:10:16.832Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bda78b55f4de5af9732",
        "email": "ts@tristanspilker.de",
        "createdAt": "2023-03-23T15:10:16.832Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bda78b55f4de5af9738",
        "email": "martin.schilling@techstars.com",
        "createdAt": "2023-03-23T15:10:16.832Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bda78b55f4de5af9740",
        "email": "dot8093@gmail.com",
        "createdAt": "2023-03-23T15:10:16.832Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bda78b55f4de5af973d",
        "email": "vanderlindenties@gmail.com",
        "createdAt": "2023-03-23T15:10:16.832Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bda78b55f4de5af977a",
        "email": "adioayoolarasaq@gmail.com",
        "createdAt": "2023-03-23T15:10:16.832Z",
        "firstName": ""
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c6bda78b55f4de5af9793",
        "email": "themodupeodumosu@gmail.com",
        "createdAt": "2023-03-23T15:10:16.832Z",
        "firstName": "Modupe"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c6bda78b55f4de5af97a2",
        "email": "k.diphokwane@gmail.com",
        "createdAt": "2023-03-23T15:10:16.832Z",
        "firstName": "Kgosietsile"
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bda78b55f4de5af97b2",
        "email": "mykelokuboyejo@gmail.com",
        "createdAt": "2023-03-23T15:10:16.832Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bda78b55f4de5af97cd",
        "email": "afurlanic@triglav.si",
        "createdAt": "2023-03-23T15:10:16.832Z",
        "firstName": ""
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bda78b55f4de5af97e1",
        "email": "rlisamak@gmail.com",
        "createdAt": "2023-03-23T15:10:16.832Z",
        "firstName": ""
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c6bda78b55f4de5af97eb",
        "email": "ola_oduwole@outlook.com",
        "createdAt": "2023-03-23T15:10:16.832Z",
        "firstName": "Oduwole"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c6bda78b55f4de5af97e8",
        "email": "akinlafe@gmail.com",
        "createdAt": "2023-03-23T15:10:16.832Z",
        "firstName": "Charles"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c6bda78b55f4de5af97fe",
        "email": "jessicajay933@gmail.com",
        "createdAt": "2023-03-23T15:10:16.833Z",
        "firstName": "Jessica"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c6bda78b55f4de5af9820",
        "email": "akah.nwamaka.d@gmail.com",
        "createdAt": "2023-03-23T15:10:16.833Z",
        "firstName": "Nwamaka"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c6bda78b55f4de5af9830",
        "email": "adedejimichealcharming37@gmail.com",
        "createdAt": "2023-03-23T15:10:16.833Z",
        "firstName": "Micheal"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c6bda78b55f4de5af984d",
        "email": "ohisohima29@gmail.com",
        "createdAt": "2023-03-23T15:10:16.833Z",
        "firstName": "Destiny"
    },
    {
        "mailType": "MAILLIST",
        "_id": "641c6bda78b55f4de5af9850",
        "email": "umbradesigner@gmail.com",
        "createdAt": "2023-03-23T15:10:16.833Z",
        "firstName": ""
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c6bda78b55f4de5af986a",
        "email": "marckyschlex@yahoo.com",
        "createdAt": "2023-03-23T15:10:16.833Z",
        "firstName": "Mary"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c6bda78b55f4de5af9875",
        "email": "mattychopper05@gmail.com",
        "createdAt": "2023-03-23T15:10:16.833Z",
        "firstName": "Mathew"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641c6bda78b55f4de5af9878",
        "email": "awowoyeifeoluwa@gmail.com",
        "createdAt": "2023-03-23T15:10:16.833Z",
        "firstName": "Ifeoluwa"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641cd19b78b55f4de56aa6f4",
        "email": "akindeleyinka1005@gmail.com",
        "createdAt": "2023-03-23T22:24:27.456Z",
        "firstName": "Yinka"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641cd3c378b55f4de56e8c5c",
        "email": "djavonsamuel@gmail.com",
        "createdAt": "2023-03-23T22:33:39.061Z",
        "firstName": "Samuel"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641cd4d978b55f4de5706670",
        "email": "dahurdayo@gmail.com",
        "createdAt": "2023-03-23T22:38:17.345Z",
        "firstName": "Adedayo"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641cd75978b55f4de574deff",
        "email": "dairoiyin@gmail.com",
        "createdAt": "2023-03-23T22:48:57.181Z",
        "firstName": "Iyinoluwa "
    },
    {
        "mailType": "WAITLIST",
        "_id": "641cd87478b55f4de576d3de",
        "email": "solathecoder@gmail.com",
        "createdAt": "2023-03-23T22:53:40.215Z",
        "firstName": "Samuel"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641cd8d678b55f4de57780aa",
        "email": "adepejubukola2@gmail.com",
        "createdAt": "2023-03-23T22:55:18.133Z",
        "firstName": "Cecilia "
    },
    {
        "mailType": "WAITLIST",
        "_id": "641d1d0978b55f4de5f1370d",
        "email": "omolaja.abubakar05@gmail.com",
        "createdAt": "2023-03-24T03:46:17.209Z",
        "firstName": "Abubakar Abdullahi "
    },
    {
        "mailType": "WAITLIST",
        "_id": "641d839578b55f4de5abcadf",
        "email": "johnoluwafemi64@gmail.com",
        "createdAt": "2023-03-24T11:03:49.103Z",
        "firstName": "John"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641d85c078b55f4de5b003c2",
        "email": "adesolaridwan2003@gmail.com",
        "createdAt": "2023-03-24T11:13:04.853Z",
        "firstName": "Ridwan Adeyemo"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641d890a78b55f4de5b6720c",
        "email": "solomonayodeleo@gmail.com",
        "createdAt": "2023-03-24T11:27:06.832Z",
        "firstName": "Solomon"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641d890e78b55f4de5b67b16",
        "email": "tonymax834@gmail.com",
        "createdAt": "2023-03-24T11:27:10.537Z",
        "firstName": "Imaeruaka beluchukwu mark "
    },
    {
        "mailType": "WAITLIST",
        "_id": "641d8df378b55f4de5bfe5a5",
        "email": "aquilaafuadajo@gmail.com",
        "createdAt": "2023-03-24T11:48:03.672Z",
        "firstName": "Emmanuel"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641d8e3178b55f4de5c0574f",
        "email": "hamzatzukrah@gmail.com",
        "createdAt": "2023-03-24T11:49:05.764Z",
        "firstName": "Opeyemi"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641d927378b55f4de5c806e3",
        "email": "emmanuelalabi05@gmail.com",
        "createdAt": "2023-03-24T12:07:15.369Z",
        "firstName": "Emmanuel"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641d952978b55f4de5cd4877",
        "email": "haryourthundey1@gmail.com",
        "createdAt": "2023-03-24T12:18:49.732Z",
        "firstName": "David"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641d98f078b55f4de5d443d1",
        "email": "danielolawale00@gmail.com",
        "createdAt": "2023-03-24T12:34:56.640Z",
        "firstName": "Olawale"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641daa4f78b55f4de5f5d32a",
        "email": "iyowujadesola@gmail.com",
        "createdAt": "2023-03-24T13:49:03.284Z",
        "firstName": "Jadesola "
    },
    {
        "mailType": "WAITLIST",
        "_id": "641dbcbf78b55f4de5183ff4",
        "email": "simon@trudenty.com",
        "createdAt": "2023-03-24T15:07:43.279Z",
        "firstName": "simon"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641dbf6a78b55f4de51da6ac",
        "email": "sixtus888hz@gmail.com",
        "createdAt": "2023-03-24T15:19:06.657Z",
        "firstName": "Sixtu"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641dd9c678b55f4de54eca70",
        "email": "nwoyejenny11@gmail.com",
        "createdAt": "2023-03-24T17:11:34.835Z",
        "firstName": "Nwoye"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641ddd1b78b55f4de555171c",
        "email": "jessieettah@gmail.com",
        "createdAt": "2023-03-24T17:25:47.642Z",
        "firstName": "Jessica"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641de31478b55f4de55fb4d2",
        "email": "bettiedward07@gmail.com",
        "createdAt": "2023-03-24T17:51:16.136Z",
        "firstName": "Anita"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641eabfe78b55f4de5b95491",
        "email": "chinonyeemedo56@gmail.com",
        "createdAt": "2023-03-25T08:08:30.914Z",
        "firstName": "Emedo "
    },
    {
        "mailType": "WAITLIST",
        "_id": "641eecff78b55f4de52a31dd",
        "email": "dotuatedji@gmail.com",
        "createdAt": "2023-03-25T12:45:51.799Z",
        "firstName": "Dotu"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641ef80c78b55f4de53d9641",
        "email": "olumidenwosu@gmail.com",
        "createdAt": "2023-03-25T13:33:00.607Z",
        "firstName": "Olumide"
    },
    {
        "mailType": "WAITLIST",
        "_id": "641f4a1878b55f4de5ccee65",
        "email": "miraclesotade@gmail.com",
        "createdAt": "2023-03-25T19:23:04.711Z",
        "firstName": "Miracle "
    },
    {
        "mailType": "WAITLIST",
        "_id": "641f59ae78b55f4de5e7f75a",
        "email": "preciouschidex82@gmail.com",
        "createdAt": "2023-03-25T20:29:34.743Z",
        "firstName": "Precious"
    },
    {
        "mailType": "WAITLIST",
        "_id": "64205c1878b55f4de5a4b9d4",
        "email": "deyeminifemi@gmail.com",
        "createdAt": "2023-03-26T14:52:08.065Z",
        "firstName": "Nifemi"
    },
    {
        "mailType": "WAITLIST",
        "_id": "642142f078b55f4de5355423",
        "email": "obiefunaalbertgeorge@gmail.com",
        "createdAt": "2023-03-27T07:17:04.807Z",
        "firstName": "Albert"
    },
    {
        "mailType": "WAITLIST",
        "_id": "6421a42f78b55f4de5e26630",
        "email": "jaydel120390@gmail.com",
        "createdAt": "2023-03-27T14:11:59.238Z",
        "firstName": "Jacie"
    },
    {
        "mailType": "WAITLIST",
        "_id": "6421a43678b55f4de5e27302",
        "email": "jay.del90@yahoo.com",
        "createdAt": "2023-03-27T14:12:06.438Z",
        "firstName": "Jacie"
    },
    {
        "mailType": "WAITLIST",
        "_id": "64229b5a78b55f4de58dec4f",
        "email": "aremuoluwakemisola88@gmail.com",
        "createdAt": "2023-03-28T07:46:34.467Z",
        "firstName": "Oluwakemisola "
    },
    {
        "mailType": "WAITLIST",
        "_id": "6422b4b578b55f4de5bac876",
        "email": "toni@siliconbear.com",
        "createdAt": "2023-03-28T09:34:45.951Z",
        "firstName": "Toni"
    },
    {
        "mailType": "WAITLIST",
        "_id": "6422e1a378b55f4de50a257d",
        "email": "leratomatsio@gmail.com",
        "createdAt": "2023-03-28T12:46:27.114Z",
        "firstName": "Lerato"
    },
    {
        "mailType": "MAILLIST",
        "_id": "64236528dd6ebab96e1b4886",
        "email": "orlhatundji@gmail.com",
        "createdAt": "2023-03-28T22:07:35.988Z"
    },
    {
        "mailType": "MAILLIST",
        "_id": "64f98c45c759e689b48f9aca",
        "email": "lerato@trudenty.com",
        "createdAt": "2023-09-07T08:39:33.358Z"
    }
]